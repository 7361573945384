import { useId, useCallback, useEffect } from 'react';
// import { useTheme } from '@mui/styles';
import { map } from './core/MapView';

const MapPositions = ({ positions, onClick }) => {
  const id = useId();

  // const theme = useTheme();

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];
    if (onClick) {
      onClick(feature.properties.id, feature.properties.index);
    }
  }, [onClick]);

  useEffect(() => {
    const colorMap =
      [
        'match',
        ['get', 'temp'],
      ];
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    const kma30 = ['#EEEEEE', '#DA87FF', '#C23EFF', '#AD07FF', '#9200E4', '#7F00BF', '#B3B4DE', '#8081C7',
      '#4C4EB1', '#1F219D', '#000390', '#87D9FF', '#3EC1FF', '#07ABFF', '#008DDE', '#0077B3',
      '#69FC69', '#1EF31E', '#00D500', '#00A400', '#008000', '#FFEA6E', '#FFDC1F', '#F9CD00',
      '#E0B900', '#CCAA00', '#FA8585', '#F63E3E', '#EE0B0B', '#D50000', '#BF0000', '#333333'];
    // const tempBaseByMonth = [-15, -15, -15, -10, -5, 5, 5, 10, 10, 0, -5, -10, -15];
    const tempBaseByMonth = [-15, -15, -5, -10, -5, 5, 5, 10, 10, 0, -5, -10, -15];

    const d = new Date();
    let baseTemp = tempBaseByMonth[d.getMonth()];

    for (let i = 0; i < kma30.length; i += 1) {
      colorMap.push(baseTemp, kma30[i]);
      baseTemp += 1;
    }
    // colorMap.push(-1, '#c0c0c0');
    colorMap.push('#c0c0c0');
    // console.log(colorMap);

    map.addLayer({
      id,
      type: 'circle',
      source: id,
      paint: {
        'circle-radius': 5,
        'circle-color': colorMap,
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('click', id, onMarkerClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('click', id, onMarkerClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [onMarkerClick]);

  useEffect(() => {
    map.getSource(id)?.setData({
      type: 'FeatureCollection',
      features: positions.map((position, index) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
        properties: {
          index,
          id: position.id,
          temp: parseInt(position.attributes.temp1, 10),
        },
      })),
    });
  }, [onMarkerClick, positions]);

  return null;
};

export default MapPositions;
