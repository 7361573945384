import React from 'react';

const ColorLegend = ({ legendData }) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {legendData.map((item) => (
      <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '33%' }}>
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: item.color,
            marginRight: '8px',
          }}
        />
        <span style={{ fontSize: '0.75em' }}>{item.name}</span>
      </div>
    ))}
  </div>
);

export default ColorLegend;
